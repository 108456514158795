import { Order } from "@/types/Order.type";

export const OrderDefault: Order = {
  _id: '',
  idRestaurant: '',
  idMenu: '',
  menuItemsOrder: [],
  total: 0,
  typeOrder: 0,
  customerName: '',
  customerAddress: {
    street: '',
    neigborhood: '',
    city: '',
    state: '',
    zipCode: 0
  },
  notes: '',
  // System
  active: true,
  deleted: false,
  createdAt: new Date(),
  updatedAt: new Date(),
}