<template>
  <div class="d-flex flex-column align-center">
    <!-- SORT BUTTON -->
    <!-- <SortSelectComp class="animate__animated animate__fadeIn" /> -->

    <!-- RESTAURANTS -->
    <v-progress-circular
      v-if="loading"
      indeterminate
      color="var(--color-white)"
    ></v-progress-circular>

    <div v-else class="w-100 mt-10 animate__animated animate__fadeIn">
      <div class="w-100" v-for="restaurant in restaurants" :key="restaurant._id">
        <RestaurantCardComp :restaurant="restaurant" class="mb-4"/>
      </div>
    </div>

  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';
import SortSelectComp from '@/components/home_view/restaurant_section/SortSelectComp.vue'
import RestaurantCardComp from '@/components/home_view/restaurant_section/RestaurantCardComp.vue'
import ApiGetRestaurants from '@/api/restaurants/ApiGetRestaurants';
import { Restaurant } from '@/types/Restaurant.type';

const loading = ref(false);
const restaurants = ref<Array<Restaurant>>([]);

onMounted(async () => {
  console.log('Getting restaurants...');
  loading.value = true;
  const { error, data } = await ApiGetRestaurants.execute();
  if (error.value) {
    console.log(error.value);
  } else {
    restaurants.value = data.value;
    console.log('Restaurants length: ', restaurants.value.length);
  }
  loading.value = false;
});
</script>

<style lang="scss" scoped>

</style>