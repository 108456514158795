<template>
  <div class="restaurant-card">
    <img ref="profileImageRef" :src="restaurant.profileImage" alt="image" loading="lazy" class="restaurant-card__logo" />
    <div class="restaurant-card__info">
      <span class="restaurant-card__name text-title">{{ restaurant.name }}</span> 
      <br />
      <span class="tc-text-light tw-medium ts-small">{{ getTypeTags }}</span>
    </div>
    <RelButton class="ml-auto px-2" text="menú" appendIcon="mdi-silverware" textColor="#E2F37C" color="high-contrast" :onClick="goTo"/>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useMain } from '@/composables/useMain'
import RelButton from '@/components/buttons/RelButton.vue'

// Types
import { Restaurant } from '@/types/Restaurant.type'

// Props
const { restaurant } = defineProps<{
  restaurant: Restaurant;
}>()

// Data
const router = useRouter()
const mainStore = useMain()
const profileImageRef = ref()

// Computed
const getTypeTags = computed(() => {
  return restaurant.typeTags.join(', ')
})

// Methods
function goTo() {
  if (mainStore.isOrderAlreadyCreated(restaurant._id)) {
    if(confirm('Ya tienes un pedido en curso, si creas uno nuevo se perderá el anterior. ¿Deseas continuar?')) {
      mainStore.clearOrder()
      mainStore.setRestaurant(restaurant)
      router.push({ name: 'restaurant', params: { slug: restaurant.slug } })
    }
  } else {
    mainStore.setRestaurant(restaurant)
    router.push({ name: 'restaurant', params: { slug: restaurant.slug } })
  }
}

// Hooks
onMounted(() => {
  profileImageRef.value.style.opacity = "0";
  profileImageRef.value.onload = () => {
    profileImageRef.value.style.transition = "opacity 1s";
    profileImageRef.value.style.opacity = "1";
  }
})
</script>

<style lang="scss" scoped>
.restaurant-card {
  width: 100%;
  height: fit-content;
  background: var(--color-white);
  border-radius: 18px;
  padding: 0.5rem;
  padding-right: 1rem;
  display: flex;
  align-items: center;
  column-gap: 1rem;
  .restaurant-card__logo {
    width: 62px;
    height: 62px;
    border-radius: 12px;
    object-fit: cover;
    object-position: center;
  }
  .restaurant-card__info {
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    .restaurant-card__name {
      font-weight: var(--font-bold);
      font-size: 1.25rem;
      color: var(--color-black);
    }
  }
}

@media only screen and (max-width: 400px) {
  .restaurant-card {
    .restaurant-card__logo {
      width: 50px;
      height: 50px;
    }
    .restaurant-card__info {
      .restaurant-card__name {
        font-size: 1rem;
      }
    }
  }
}
</style>