<template>
  <div class="home-view" @scroll="scrolling">
    <v-container class="px-0 max-width mb-8">
      <HeaderComp class="mb-6" />
      <!-- RESTAURANTS -->
      <RestaurantSectionComp />
    </v-container>
  </div>
</template>

<script setup lang="ts">
// Composables
import { ref } from "vue";
import { useTitle } from '@vueuse/core'
// Components
import HeaderComp from '@/components/home_view/HeaderComp.vue'
import RestaurantSectionComp from '@/components/home_view/RestaurantSectionComp.vue';

// Data
const scroll = ref(false);

// Composables
useTitle('Rel Menu | Busca tu restaurante favorito')

// Methods
const scrolling = (e: any) => {
  const scrollTop = e.target.scrollTop
  if (scrollTop > 0) {
    scroll.value = true
  } else {
    scroll.value = false
  }
}
</script>

<style lang="scss" scoped>
.home-view {
  height: 100%;
  width: 100%;
  padding: 1rem;
  // background: linear-gradient(169.61deg, #FA5E23 1.19%, #E2F37C 99.95%);
  background: #932EE4;
  overflow-y: auto;
}
.sticky-search {
  position: sticky;
  top: 0;
  z-index: 100;
}
</style>
