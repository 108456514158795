<template>
  <div class="not-found">
    <div class="not-found__content">
      <div class="circle-bg"></div>
      <p class="tc-rel ts-h2 tw-bold text-center w-100 lh-1 mb-3">
        Oops! parece que alguien se ha comido este menú 😕
      </p>
      <p class="tc-rel text-center lh-1 mb-6 w-75">
        No te preocupes, le damos una segunda oportunidad a tu hambre
      </p>
      <MonsterImage class="mb-4" />
      <RelButton text="Ver otros menús disponibles" :onClick="goToRestaurants" />
    </div>
  </div>
</template>

<script setup lang="ts">
import MonsterImage from "@/components/extras/MonsterImage.vue";
import RelButton from "../buttons/RelButton.vue";
import { useRouter } from "vue-router";

// Data
const router = useRouter();

// Methods
const goToRestaurants = () => {
  router.push({ name: 'home' });
}

</script>

<style lang="scss" scoped>
.not-found {
  width: 100%;
  height: 100%;
  background-color: var(--color-rel);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;

  &__content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--color-white);
    position: relative;

    .circle-bg {
      width: 100%;
      aspect-ratio: 1/1;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%) scale(1.7);
      background-color: var(--color-white);
      border-radius: 50%;
      z-index: -1;
    }
  }
}
</style>
