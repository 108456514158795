<template>
  <div class="order-total">
    <v-select v-model="orderTypeSelected" :items="deliveyOptions" :prepend-inner-icon="orderTypeIcon" item-title="text"
      item-value="value" class="order-total__select" menu-icon="mdi-chevron-down" variant="solo" hide-no-data />

    <div class="order-total__price w-100">
      <span class="order-total__price__text">Total de la orden: </span>
      <span class="order-total__price__value">${{ order.total }} MXN</span>
    </div>
    <p class="lh-1 ts-smaller mb-2">
      *El total de la orden no contempla costos de
      envío ni costos extras que pueda tener el restaurante.
    </p>
    <div class="w-100 d-flex justify-space-between">
      <RelButton text="Regresar" color="background" textColor="#383838" light flat :onclick="goBack" />
      <RelButton text="Confirmar pedido" color="secondary-2" textColor="#FFFFFF" appendIcon="mdi-check-all" flat :onclick="confirmOrder" />
    </div>
  </div>
</template>

<script setup lang="ts">
import RelButton from "@/components/buttons/RelButton.vue";
import { ref, computed, onBeforeMount } from "vue";
import { useMain } from "@/composables/useMain";
import { TypesOrder } from "@/types/Order.type";
import type { TypeOrder } from "@/types/Order.type";

const { order, restaurant } = useMain();

const orderTypeSelected = ref(0);
const items = ref([
  { text: "Entrega a domicilio", icon: "mdi-moped", value: 0 },
  { text: "Ordena y recoja", icon: "mdi-car-hatchback", value: 1 },
]);

// Emits
const emit = defineEmits(["confirmOrder", "goBack"]);

// Computed
const orderTypeIcon = computed(() => {
  return items.value[orderTypeSelected.value].icon;
});

const deliveyOptions = computed(() => {
  const ops = []
  if(restaurant.value.delivery)
    ops.push({ text: "Entrega a domicilio", icon: "mdi-moped", value: 0 })
  if(restaurant.value.toGo)
    ops.push({ text: "Ordena y recoja", icon: "mdi-car-hatchback", value: 1 })
  orderTypeSelected.value = ops[0].value
  return ops
})

// Methods
function goBack() {
  emit("goBack");
}

function confirmOrder() {
  switch (orderTypeSelected.value) {
    case 0:
      order.value.typeOrder = TypesOrder.DELIVERY
      break;
    case 1:
      order.value.typeOrder = TypesOrder.TO_GO
      break;
  }
  emit("confirmOrder");
}
</script>

<style lang="scss" scoped>
.order-total {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 1rem 0 0;

  .order-total__select {
    :deep(.v-field) {
      box-shadow: none;
      border-radius: 1rem;
      padding: 0rem 1rem;
      background-color: #f3f3f3;
    }

    :deep(.v-icon) {
      color: var(--color-black);
      opacity: 1;
    }

    :deep(.v-select__selection-text) {
      color: var(--color-black);
      font-weight: var(--font-bold);
      opacity: 1;
    }
  }

  &__price {
    display: flex;
    align-items: flex-start;

    &__text {
      font-size: 1.2rem;
      font-weight: var(--font-bold);
      color: var(--color-black);
    }

    &__value {
      font-size: 1.2rem;
      font-weight: var(--font-bold);
      color: var(--color-secondary-2);
      margin-left: 1rem;
    }
  }
}
</style>
