<template>
  <div class="order-items-list">
    <OrderItem v-for="item in orderItems" :key="item.idMenuItem" :item="item" />
  </div>
</template>

<script setup lang="ts">
import OrderItem from "@/components/order_view/OrderItem.vue";
import { useMain } from "@/composables/useMain";
import { computed } from "vue";

// Data
const { order } = useMain();

// Computed
const orderItems = computed(() => order.value.menuItemsOrder);
</script>

<style lang="scss" scoped>
.order-items-list {
  display: flex;
  flex-direction: column;
  height: fit-content;
  position: relative;
}
</style>
