<template>
  <v-app class="app">
    <router-view />
    <LabelEnv v-if="!getIsProduction" />
    <MyOrderButton v-if="isOrderHasItems" />
  </v-app>
</template>

<script lang="ts" setup>
import LabelEnv from '@/components/extras/LabelEnv.vue';
import MyOrderButton from './components/extras/MyOrderButton.vue';
import { computed } from 'vue'
import { useMain } from '@/composables/useMain'

const { order } = useMain()

let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty("--vh", `${vh}px`);
window.addEventListener("resize", () => {
  // We execute the same script as before
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
});

// computed
const getIsProduction = computed(() => process.env.VUE_APP_ENVIROMENT === 'production')
const isOrderHasItems = computed(() => order.value?.menuItemsOrder?.length > 0)
</script>

<style lang="scss">
@import '@/style/fonts.css';
@import '@/style/main.scss';
.app {
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  position: relative;
}

@media screen and (-webkit-min-device-pixel-ratio:0) { 
  select,
  textarea,
  input {
    font-size: 16px;
  }
}
</style>
