import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import { createPinia } from 'pinia'
import { loadFonts } from './plugins/webfontloader'
import 'animate.css';
import { createI18n } from 'vue-i18n'
import translateText from './lang/translateText'

loadFonts()

const pinia = createPinia()

const i18n = createApp({
  messages: translateText,
  fallbackFormat: 'en',
  locale: navigator.language.startsWith('es') ? 'es' : 'en'
})

createApp(App)
  .use(pinia)
  .use(router)
  .use(vuetify)
  .mount('#app')
