import { storeToRefs } from 'pinia';
import { useMainStore } from '@/stores/mainStore'
import { Restaurant } from '@/types/Restaurant.type';
import { Order } from '@/types/Order.type';
import { ItemOrder } from '@/types/ItemOrder.type';
import { MenuItem } from '@/types/MenuItem.type';
import { isEmptyString } from '@/utils/IsEmptyString';
import { RestaurantId } from '@/types/Id.types';

export const useMain = () => {
  const mainStore = useMainStore()
  const { order, restaurant, name, street, streetNumber, neighborhood, savedInfo, menuLanguages, language  } = storeToRefs(mainStore)

  // ItemOrder
  const createItemOrder = (menuItem: MenuItem): ItemOrder => {
    const itemOrder = {
      idMenuItem: menuItem._id,
      name: language.value == 'es' ? menuItem.name_es : menuItem.name_en,
      description_es: language.value == 'es' ? menuItem.description_es || undefined : menuItem.description_en || undefined,
      price: menuItem.promoPrice ?? menuItem.price,
      quantity: 1,
      total: menuItem.promoPrice ?? menuItem.price,
    }
    return itemOrder
  }
  /**
   * 
   * ******************** ORDER ********************
   */
  // Order
  function setOrder(value: Order) {
    mainStore.setOrder(value)
  }

  function setOrderRestaurant(value: RestaurantId) {
    mainStore.setOrderRestaurant(value)
  }

  // get item order
  function getItemOrderQuantity(idMenuItem: string): number {
    const itemOrder = order.value?.menuItemsOrder?.find(
      (item) => item.idMenuItem === idMenuItem
    )
    return itemOrder ? itemOrder.quantity : 0
  }

  // add item to order
  function addMenuItemToOrder(menuItem: MenuItem) {
    const index = order.value.menuItemsOrder.findIndex(
      (item) => item.idMenuItem === menuItem._id
    )
    if (index === -1) {
      setOrderRestaurant(restaurant?.value?._id || '')
      mainStore.addMenuItemToOrder(createItemOrder(menuItem))
    } 
    else {
      const itemOrder = order.value.menuItemsOrder[index]
      itemOrder.quantity++
      itemOrder.total = itemOrder.price * itemOrder.quantity
      mainStore.editMenuItemInOrder(itemOrder)
    }
    updateTotalOrder()
  }

  // remove item from order
  function removeMenuItemFromOrder(idMenuItem: string) {
    const index = order.value.menuItemsOrder.findIndex(
      (item) => item.idMenuItem === idMenuItem
    )
    if (index !== -1) {
      const itemOrder = order.value.menuItemsOrder[index]
      if (itemOrder.quantity > 1) {
        itemOrder.quantity--
        itemOrder.total = itemOrder.price * itemOrder.quantity
        mainStore.editMenuItemInOrder(itemOrder)
      } 
      else {
        setOrderRestaurant('')
        mainStore.removeMenuItemFromOrder(idMenuItem)
      }
    }
    updateTotalOrder()
  }

  // Check ir Order is not empty
  function isOrderAlreadyCreated(currentRestaurantId: string): boolean {
    if (!isEmptyString(order.value.idRestaurant)) {
      return order.value.idRestaurant !== currentRestaurantId
    }
    return false
  }

  // clear order
  function clearOrder() {
    mainStore.clearOrder()
  }

  // update total order
  function updateTotalOrder() {
    let total = 0
    order.value.menuItemsOrder.forEach((item) => {
      total += item.total
    })
    order.value.total = total
  }

  /**
   * 
   * ******************** RESTAURANT ********************
   */

  // set restaurant
  function setRestaurant(value: Restaurant) {
    mainStore.setRestaurant(value)
  }

  ///////////// MENU LANGUAGES //////////////////////////////
  function setMenuLanguages(value: Object) {
    mainStore.setMenuLanguages(value)
  }

  return {
    // Order
    order,
    setOrder,
    setOrderRestaurant,
    addMenuItemToOrder,
    removeMenuItemFromOrder,
    getItemOrderQuantity,
    isOrderAlreadyCreated,
    updateTotalOrder,
    clearOrder,

    // Restaurant
    restaurant,
    setRestaurant,

    //MenuLanguages
    menuLanguages,
    setMenuLanguages,

    // User
    name,
    street,
    streetNumber,
    neighborhood,
    savedInfo
  }
}