<template>
  <div class="d-flex align-center flex-column w-100">
    <v-img
      class="mb-3 animate__animated animate__fadeInDown"
      src="@/assets/logo.svg"
      width="160"
    ></v-img>
    <h3
      class="tc-white text-title text-center w-75 mb-4 animate__animated animate__fadeInDown a-delay-1"
    >
      Sin duda la forma más sencilla y rápida de llegar al mundo digital
    </h3>
    <RelButton
      @click="sendMessage"
      class="px-12 animate__animated animate__fadeInDown a-delay-2"
      text="Agrega un negocio"
      color="secondary-2"
      textColor="#F8EEFF"
    />
  </div>
</template>

<script setup lang="ts">
import RelButton from "@/components/buttons/RelButton.vue";

//Methods
const sendMessage = () =>{
  let message = "https://wa.me/52" + '6123095025' + "?text= *Hola, que tal!* Me gustaría más información acerca de Rel Menu%0a%0a";

  window.open(message, "_blank");
}
</script>

<style lang="scss" scoped></style>
