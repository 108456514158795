import { MenuId, OrderId, RestaurantId } from "@/types/Id.types"
import { Address } from "@/types/Address.type"
import { ItemOrder } from "@/types/ItemOrder.type"

export type Order = {
  _id: OrderId
  idRestaurant: RestaurantId,
  idMenu: MenuId
  menuItemsOrder: ItemOrder[],
  total: number
  typeOrder: TypeOrder
  customerName: string
  customerAddress: Address
  notes: string
  // System
  active: boolean;
  deleted: boolean;
  createdAt: Date;
  updatedAt: Date;
}

export const TypesOrder = Object.freeze({
  DELIVERY: 0,
  TO_GO: 1,
});

export type TypeOrder = typeof TypesOrder[keyof typeof TypesOrder];