<template>
  <div class="restaurant-menu-comp max-width">
    <div class="d-flex justify-center" v-if="loading">
      <v-progress-circular indeterminate color="var(--color-rel)"></v-progress-circular>
    </div>
    <ChipTabsComp :categories="getMenuCategories" @setCategory="filterCategories($event)" />
    <div class="restaurant-menu__categorie" v-for="(categorie, index) in getFilterMenuCategories" :key="index">
      <h2 class="tw-bold ts-h2 pl-4 restaurant-menu__categorie__name text-title">{{ language == 'es' ? categorie.name_es : categorie.name_en}}</h2>
      <v-list density="compact">
        <v-list-item v-for="(menuItem, i) in categorie.menuItems" :key="menuItem._id">
          <MenuItemComp :menuItem="menuItem" />
        </v-list-item>
      </v-list>
    </div>
  </div>
</template>

<script setup>
import ChipTabsComp from './restaurant_menu/ChipTabsComp.vue';
import MenuItemComp from '@/components/restaurant_view/restaurant_menu/MenuItemComp.vue';
import ApiGetMenuByIdRestaurant from '@/api/menus/ApiGetMenuByIdRestaurant';
import { useMainStore } from '@/stores/mainStore';
import { storeToRefs } from 'pinia';
import { useMain } from '@/composables/useMain';
import { ref, computed } from 'vue';
// Props
const { restaurantId } = defineProps({
  restaurantId: {
    required: true,
    default: ""
  }
});

// Data
const { order } = useMain();
const loading = ref(false);
const menu = ref({});
const selectedCategory = ref({})

//Use store
const mainStore = useMainStore()
const { language } = storeToRefs(mainStore)
const { setMenuLanguages } = useMain()


const getMenu = async () => {
  loading.value = true;
  const { error, data } = await ApiGetMenuByIdRestaurant.execute({ idRestaurant: restaurantId });
  if (error.value) {
    console.log(error.value);
  } else {
    menu.value = data.value;
    order.value.idMenu = data.value._id;
    setMenuLanguages({
      es_lang : data.value.es_lang,
      en_lang : data.value.en_lang
    })

  }
  loading.value = false;
};

const getMenuCategories = computed(() => {
  if (menu.value?.categories) {
    return menu.value.categories
  }
  return [];
});

const getFilterMenuCategories = computed(() => {
  if (menu.value?.categories) {
    if (selectedCategory.value._id) {
      return menu.value.categories.filter((category) => category._id == selectedCategory.value._id);
    } else {
      return menu.value.categories
    }

  }
  return [];
});

const filterCategories = (category) => {
  selectedCategory.value = category
}

getMenu();
</script>

<style lang="scss" scoped>
.restaurant-menu-comp {
  width: 100%;
  height: fit-content;
  margin: 1rem auto;
  padding-bottom: 6rem;
}

.restaurant-menu__categorie {
  width: 100%;
  height: fit-content;
  padding: 1rem 0;
  margin-bottom: 1rem;

  &__name {
    text-transform: capitalize;
  }
}
</style>