<template>
  <div class="order-item">
    <div class="order-item__info">
      <h4 class="ts-h3">{{ item.name }}</h4>
      <p class="lh-1 tw-medium tc-text-light">{{ item.description }}</p>
      <div class="d-flex gap-3 pt-1">
        <p class="tw-bold">Cantidad: {{ item.quantity }}</p>
        <p class="tw-bold">$ {{ item.total }}</p>
      </div>
    </div>
    <v-btn icon rounded flat @click="removeItem">
      <v-icon class="tc-red">mdi-trash-can-outline</v-icon>
    </v-btn>
  </div>
</template>

<script setup lang="ts">
import { ItemOrder } from "@/types/ItemOrder.type";
import { useMain } from "@/composables/useMain";

// Props
const { item } = defineProps<{
  item: ItemOrder;
}>();

// Data
const { removeMenuItemFromOrder } = useMain();

// Methods
function removeItem() {
  removeMenuItemFromOrder(item.idMenuItem);
}
</script>

<style lang="scss" scoped>
.order-item {
  display: flex;
  padding: 1rem 0;
  border-bottom: 2px solid #eaeaea;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;

  &__info {
    display: flex;
    flex-direction: column;
  }
}
</style>