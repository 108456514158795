<template>
  <div class="label-env">
    {{ getEnviroment }}
  </div>
</template>

<script setup lang="ts">
// Hooks
import { computed } from "vue";
// Computed
const getEnviroment = computed(() => process.env.VUE_APP_ENVIROMENT || 'No Enviroment')

const getColor = computed(() => {
  if (getEnviroment.value === 'localhost') {
    return 'var(--color-light-contrast)'
  } else if (getEnviroment.value === 'development') {
    return 'var(--color-high-contrast)'
  } else if (getEnviroment.value === 'production') {
    return 'var(--color-pink)'
  } else {
    return 'black'
  }
})
</script>

<style lang="scss" scoped>
.label-env {
  position: absolute;
  top: 0;
  left: 0;
  background: v-bind(getColor);
  color: var(--color-secondary-1);
  padding: 0.2rem 0.4rem;
  font-size: 0.8rem;
  font-weight: 600;
  z-index: 999;
  border-radius: 0 12px 12px 0;
}
</style>