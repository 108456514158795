<template>
  <div class="restaurant-view">
    <div class="loading-comp" v-if="loading">
      <v-progress-circular
        indeterminate
        color="var(--color-white)"
      ></v-progress-circular>
    </div>
    <div class="restaurant-view__content" v-if="restaurantFounded">
      <RestaurantHeaderComp :restaurantInfo="restaurantInfo" />
      <RestaurantMenuComp :restaurantId="restaurantInfo._id" />
    </div>
    <NotFoundRestaurant v-if="!loading && !restaurantFounded" />
  </div>
</template>

<script setup lang="ts">
import RestaurantHeaderComp from '@/components/restaurant_view/RestaurantHeaderComp.vue';
import RestaurantMenuComp from '@/components/restaurant_view/RestaurantMenuComp.vue';
import NotFoundRestaurant from '../components/extras/NotFoundRestaurant.vue';
import ApiGetRestaurantBySlug from '@/api/restaurants/ApiGetRestaurantBySlug';
import { ref, onBeforeMount, computed } from 'vue';
import { useRoute } from 'vue-router';

// Types
import { useMain } from "@/composables/useMain";
import { useTitle } from '@vueuse/core'
import { Restaurant } from '@/types/Restaurant.type';

// Data
const { restaurant, setRestaurant } = useMain();
const route = useRoute();
const loading = ref(false);
const restaurantFounded = ref(false);
const restaurantInfo = ref<Restaurant>({} as Restaurant);


// Methods
const getRestaurant = async () => {
  if (!restaurant?.value?._id) {
    console.log('Getting restaurant by Slug...');
    loading.value = true;
    const { error, data } = await ApiGetRestaurantBySlug.execute({ slug: route.params.slug });
    if (error.value) {
      console.log(error.value);
      restaurantFounded.value = false;
    } else {
      setRestaurant(data.value);
      restaurantInfo.value = data.value;
      restaurantFounded.value = true;
    }
    loading.value = false;
  } else {
    restaurantInfo.value = restaurant.value;
    restaurantFounded.value = true;
  } 
}

// Computeds
const restaurantName = computed(() => {
  return restaurant?.value?.name ? `${restaurant.value.name} | RelMenu` : 'No restaurant set | RelMenu' 
});

// Hooks
onBeforeMount(() => {
  getRestaurant();
}),

// Composables
useTitle(restaurantName) 
</script>

<style lang="scss" scoped>
.restaurant-view {
  height: 100%;
  width: 100%;
  overflow-y: auto;

  &__content {
    height: 100%;
    width: 100%;
  }

  .loading-comp {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--color-rel);
  }
}
</style>