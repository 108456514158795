<template>
  <div class="image-cover-comp" :style="`background-image: url('${restaurantInfo.profileImage}')`">
    <div class="image-cover__overlay">
      <div class="image-cover__overlay__content">
        <div class="content-options">
          <v-btn @click="changeLanguage" class="lang-btn" size="small">
            <span class="tc-text-title tw-bold ts-normal">{{ language }}</span>
          </v-btn>
          <!-- <v-btn icon size="small" variant="flat" @click="goHome">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn> -->

          <v-btn v-if="!staticMenu && !restaurantInfo.closed" size="small">
            <span class="tc-text-title tw-bold ts-normal">$ {{ order.total || 0 }} MXN</span>
          </v-btn>
        </div>
        <div class="content-info max-width">
          <h2 class="tc-light-contrast ts-h2 ml-6 text-title">{{ restaurantInfo.name }}
            <i :class="restaurantInfo.closed ? 'tc-red' : 'tc-green'" class="mdi mdi-circle ts-normal ml-1"/>
          </h2>
          <p class="categories-chip tc-light-contrast tw-bold ml-6">{{ categories }}</p>
          <p class="categories-chip tc-light-contrast tw-bold ml-6 mb-2">{{ restaurantInfo.location.city + " " +  restaurantInfo.location.estate}}</p>
          <div class="social-content">
            <v-btn v-if="restaurantInfo.facebookUrl" @click="openUrl(restaurantInfo.facebookUrl)"
              class="ml-6 social-btn" color="#F8EEFF" variant="text" density="compact" icon="mdi-facebook">
            </v-btn>
            <v-btn v-if="restaurantInfo.instagramUrl" @click="openUrl(restaurantInfo.instagramUrl)"
              class="ml-3 social-btn" color="#F8EEFF" variant="text" density="compact" icon="mdi-instagram">
            </v-btn>
            <v-btn v-if="restaurantInfo.locationUrl" @click="openUrl(restaurantInfo.locationUrl)"
              class="ml-3 social-btn" color="#F8EEFF" variant="text" density="compact" icon="mdi-map-marker">
            </v-btn>
            
          </div>

          <RestuarantDeliveryOptions :restaurantInfo="restaurantInfo" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import RestuarantDeliveryOptions from "./restaurant_header/RestuarantDeliveryOptions.vue";
import { Restaurant } from "@/types/Restaurant.type";
import { computed, ref } from "vue";
import { useMain } from "@/composables/useMain";
import { useMainStore } from "@/stores/mainStore";
import { storeToRefs } from 'pinia';
import { useRouter, useRoute } from "vue-router";

// Data
const { order } = useMain();
const router = useRouter();
const route = useRoute();
const staticMenu = ref(route.query.static);

//Use store
const mainStore = useMainStore()
const { language } = storeToRefs(mainStore)
const { menuLanguages } = useMain()

// Props
const { restaurantInfo } = defineProps<{
  restaurantInfo: Restaurant;
}>();

// Computed
const categories = computed(() => {
  if (restaurantInfo) {
    return restaurantInfo.typeTags.join(" • ");
  } else {
    return "";
  }
});

const isOpen = computed(() => {
  return restaurantInfo.closed ? "Cerrado" : "Abierto"
});

// Methods
const goHome = () => {
  router.push({ name: "home" });
}

// const getMenuLanguajes = (menuLangs) => {
//   return menuLangs
// }

const openUrl = (url: string) => {
  window.open(url, '_blank')
}

const changeLanguage = () => {
  if (language.value == 'es') {
    if (menuLanguages.value.en_lang)
      mainStore.setLanguage('en');
    else
      alert("No disponible ahora / Not available now");
  } else {
    if (menuLanguages.value.es_lang)
      mainStore.setLanguage('es');
    else
      alert("No disponible ahora / Not available now");
  }
}

mainStore.setLanguage('es');
</script>

<style lang="scss" scoped>
.image-cover-comp {
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 480px;
  background-color: #8000ff;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;

  .image-cover__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(0, 0, 0);
    background: linear-gradient(180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.6) 100%);
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;

    .image-cover__overlay__content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      height: 100%;
      padding: 1rem;

      .content-options {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .lang-btn {
          text-transform: lowercase;
        }
      }

      .content-info {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        position: relative;
        padding-bottom: 36px;
        height: 100%;
        width: 100%;
        margin: 0 auto;

        .content-info__location {
          display: flex;
          align-items: center;
          gap: 0.2rem;
        }
      }
    }
  }
}

@media only screen and (max-width: 414px) {
  .image-cover-comp {
    max-height: 375px;
  }
}
</style>
