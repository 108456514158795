import { ItemOrder } from "@/types/ItemOrder.type";
import { TypeOrder, TypesOrder } from "@/types/Order.type";

export const buildWhatsappMessage = (payload : QueryParams) => {
  let url = "https://wa.me/52" + payload.phone + "?text= *Hola, que tal!* Me gustaría ordenar lo siguiente:%0a%0a";

  payload.menuItems.forEach((item) => {
    url = url + item.name + "%20(cant." + item.quantity + ")%0a";
  }) 

  url = url + "%0a*Total:*%20$" + payload.total + "%0a%0a" +
  "*Pedido a nombre de:*%20" + payload.client.name + "%0a" +
  "*Dirección de envío:*%20" + payload.client.street + "%20" + payload.client.streetNumber + ",%20" + payload.client.neighborhood + "%0a" +
  "*Notas:*%20" + payload.client.notes + "%0a%0a"
  url = url + "*Tipo de pedido:*%20" + getTypeOrder(payload.typeOrder) + "%0a%0a"
  url = url + "*Gracias!*";

  return url;
}

const getTypeOrder = (typeOrder: TypeOrder): string => {
  if (typeOrder == TypesOrder.DELIVERY) {
    return "Envío domicilio"
  } 
  if (typeOrder == TypesOrder.TO_GO) {
    return "Para recojer"
  }
  return "No definido"
}

type QueryParams = {
  phone: string | undefined;
  menuItems: ItemOrder[]
  typeOrder: TypeOrder
  total: number
  client: {
    name: string
    street: string
    streetNumber: string
    neighborhood: string
    notes: string
  }
}
