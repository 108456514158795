<template>
  <div class="content-info__modes">
    <div
      class="content-info__modes_item"
      :class="{ enable: restaurantInfo.delivery }"
    >
      <v-icon size="large">mdi-moped</v-icon>
      <p class="ts-small tw-bold text-center lh-1">Entrega<br />domicilio</p>
    </div>
    <div class="divider"></div>
    <div
      class="content-info__modes_item"
      :class="{ enable: restaurantInfo.dineIn }"
    >
      <v-icon size="large">mdi-storefront-outline</v-icon>
      <p class="ts-small tw-bold text-center lh-1">
        Comer en<br />establecimiento
      </p>
    </div>
    <div class="divider"></div>
    <div
      class="content-info__modes_item"
      :class="{ enable: restaurantInfo.toGo }"
    >
      <v-icon size="large">mdi-car-hatchback</v-icon>
      <p class="ts-small tw-bold text-center lh-1">Ordene y<br />recoja</p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Restaurant } from "@/types/Restaurant.type";

const { restaurantInfo } = defineProps<{
  restaurantInfo: Restaurant;
}>();
</script>

<style lang="scss" scoped>
.content-info__modes {
  transform: translateX(-50%);
  position: absolute;
  bottom: -56px;
  left: 50%;

  width: 100%;
  height: fit-content;
  background-color: var(--color-white);
  border-radius: 18px;
  box-shadow: var(--bs-dark);
  padding: 0.7rem 0 1rem;

  display: flex;
  justify-content: space-between;
  align-items: center;

  .content-info__modes_item {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--color-purple);
    p {
      color: var(--color-purple);
    }
  }

  .enable {
    color: var(--color-high-contrast);
    p {
      color: var(--color-high-contrast);
    }
  }

  .divider {
    min-width: 2px;
    height: 48px;
    background-color: var(--color-checker);
  }
}
</style>
