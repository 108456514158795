<template>
  <div class="chip-tabs mx-auto">
    <div class="chip-tabs-container">
      <v-chip @click="changeCategory(categoryAll)" class="ms-4 me-3 px-3 py-1 chip-tab ts-small tw-bold" color="#932EE4" :variant="selectedCategory._id == 0 ? 'flat' : 'outlined'"
        size="defaul">{{ language == 'es' ? categoryAll.name_es : categoryAll.name_en }}</v-chip>
      <v-chip @click="changeCategory(category)" class="me-3 px-3 py-1 chip-tab ts-small tw-bold" color="#932EE4" :variant="category._id == selectedCategory._id ? 'flat' : 'outlined'"
        size="defaul" v-for="(category, index) in getCategories" :key="index">
        {{ language == 'es' ? category.name_es : category.name_en }}</v-chip>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, watch, reactive, onBeforeMount } from "vue";
import { useMainStore } from "@/stores/mainStore";
import { storeToRefs } from "pinia";

//Data
const selectedCategory = ref({});
const categoryAll = ref({
  _id: 0,
  name_es: "Todas",
  name_en: "All"
})

//Props
const props = defineProps({
  categories: {
    required: true,
    default: []
  }
});

//Use store
const mainStore = useMainStore()
const { language } = storeToRefs(mainStore)

//Emits
const emit = defineEmits(['setCategory']);

//Computed
const getCategories = computed(() => {
  return props.categories;
});

// Methods
const changeCategory = (value) => {
  selectedCategory.value = Object.assign({}, value);
  emit('setCategory', selectedCategory.value);
}

selectedCategory.value = categoryAll.value;
</script>

<style lang="scss" scoped>
.chip-tabs {
  width: 100%;
  text-transform: capitalize;
  margin-top: 5rem;
  margin-bottom: 1rem;
  .chip-tabs-container {
    overflow-x: auto;
    display: flex;
    flex-wrap: nowrap;

    .chip-tab {
      min-width: fit-content;
      display: inline-block !important;
    }
  }
}
</style>
