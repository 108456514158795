<template>
  <v-btn 
    icon 
    :color="`var(--color-${color})`"
    :disabled="disabled"
    :loading="loading"
    size="small" 
    variant="flat"
    @click="onClick"
  >
    <v-icon size="24">{{ icon }}</v-icon>
  </v-btn>
</template>

<script setup lang="ts">
import { computed, PropType } from 'vue'

type Colors = 'pink' | 'blue' | 'green' | 'yellow' | 'orange' | 'red' | 'purple' | 'white' | 'black' | 'transparent' | 'rel' | 'high-contrast' | 'secondary-1' | 'secondary-2'

const { color, textColor, light, disabled, icon, loading, onClick } = defineProps({
  color: { type: String as PropType<Colors>, default: 'rel' },
  textColor: { type: String, default: 'white' },
  light: { type: Boolean, default: false },
  disabled: { type: Boolean, default: false },
  icon: { type: String, default: 'mdi-plus' },
  loading: { type: Boolean, default: false },
  onClick: { type: Function as PropType<() => void>, default: () => {} },
})

// const getLight = computed(() => {
//   return light ? '#1E1E1E' : '#FFFFFF'
// })

</script>

<style lang="scss" scoped>
.v-btn {
  border-radius: 12px;
  :deep(.v-icon) { color: v-bind(textColor); }
}
</style>
