<template>
  <div class="order-button-container">
    <RelButton
      :text="`Ver mi order (${getItemOrderCount})`"
      class="order-button animate__animated animate__backInUp"
      :onClick="() => showOrderView()"
      color="secondary-2"
      textColor="#F8EEFF"
    />
  </div>

  <OrderView :showOrderProp="showDialog" @hide-order="showDialog = false" />
</template>

<script setup lang="ts">
import RelButton from "@/components/buttons/RelButton.vue";
import OrderView from "@/views/OrderView.vue";
import { useMain } from "@/composables/useMain";
import { ref, computed } from "vue";

const { order } = useMain();
const showDialog = ref(false);

const getItemOrderCount = computed(() => {
  let count = 0;
  if (order.value?.menuItemsOrder) {
    order.value.menuItemsOrder.forEach((item) => {
      count += item.quantity;
    });
  }
  return count;
});

function showOrderView() {
  showDialog.value = !showDialog.value;
}
</script>

<style lang="scss" scoped>
.order-button-container {
  top: calc(var(--vh, 1vh) * 90);
  justify-content: center;
  position: absolute;
  display: flex;
  z-index: 100;
  width: 100%;
  left: 0;
}
.order-button {
  height: 48px;
  width: 60%;
  max-width: 364px;
  box-shadow: var(--bs-dark) !important;
}
</style>
