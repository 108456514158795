import { ItemOrder } from '@/types/ItemOrder.type'
import { MenuItemId, RestaurantId } from '@/types/Id.types'
import { Order } from '@/types/Order.type'
import { Restaurant } from '@/types/Restaurant.type'
import { defineStore } from 'pinia'
import { useLocalStorage } from '@vueuse/core'
import type { RemovableRef } from "@vueuse/shared";

// Default Objects
import { OrderDefault } from '@/common/defaultObjects'

export const useMainStore = defineStore('main', {
  state: (): MainStore => ({
    order: Object.assign({}, OrderDefault),
    restaurant: {} as Restaurant,
    // Datos de usuario
    name: useLocalStorage('name', ''),
    street: useLocalStorage('street', ''),
    streetNumber: useLocalStorage('streetNumber', ''),
    neighborhood: useLocalStorage('neighborhood', ''),
    savedInfo: useLocalStorage('savedInfo', false),
    language: useLocalStorage('language', ''),
    menuLanguages: useLocalStorage('menuLanguages', {})
  }),
  getters: {
    getOrder: (state) => state.order,
    getRestaurant: (state) => state.restaurant,
    getLanguage: (state) => state.language,
    getMenuLanguages: (state) => state.menuLanguages
  },
  actions: {
    // Set Order
    setOrder(value: Order) {
      this.order = value
    },

    // Set Order Restaurant
    setOrderRestaurant(value: RestaurantId) {
      this.order.idRestaurant = value
    },

    // Add ItemOrder to Order
    addMenuItemToOrder(menuItem: ItemOrder) {
      this.order.menuItemsOrder.push(menuItem)
    },

    // Edit ItemOrder in Order
    editMenuItemInOrder(menuItem: ItemOrder) {
      const index = this.order.menuItemsOrder.findIndex(
        (item) => item.idMenuItem === menuItem.idMenuItem
      )
      this.order.menuItemsOrder[index] = menuItem
    },

    // Remove ItemOrder from Order
    removeMenuItemFromOrder(idMenuItem: MenuItemId) {
      this.order.menuItemsOrder = this.order.menuItemsOrder.filter(
        (item) => item.idMenuItem !== idMenuItem
      )
    },

    // Clear Order
    clearOrder() {
      this.order = Object.assign({}, OrderDefault)
      this.order.menuItemsOrder = []
    },

    ///////////////////////////// RESTAURANT /////////////////////////////
    // Set Restaurant
    setRestaurant(payload: Restaurant) {
      this.restaurant = payload
    },

    /////////////////Translate///////////////////////////////////////
    setLanguage(value: string) {
      this.language = value;
    },

    setMenuLanguages(value: object) {
      this.menuLanguages = value;
    }
  },
})

export interface MainStore {
  restaurant?: Restaurant
  order: Order
  name: RemovableRef<string>
  street: RemovableRef<string>
  streetNumber: RemovableRef<string>
  neighborhood: RemovableRef<string>
  savedInfo: RemovableRef<boolean>
  language: RemovableRef<string>
  menuLanguages: RemovableRef<Object>
}