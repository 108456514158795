import { useFetch } from '@vueuse/core'

const BASE_URL = process.env.VUE_APP_BASE_URL || 'http://localhost:3000'

export default {
  async execute({ idRestaurant }: QueryParams) {
    const { isFetching, error, data } = await useFetch(`${BASE_URL}/menus/${idRestaurant}`).get().json()
    return { isFetching, error, data }
  }
}

type QueryParams = {
  idRestaurant: string | string[] 
}