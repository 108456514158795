<template>
  <v-dialog
    v-model="show"
    fullscreen
    :scrim="false"
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-card-title class="d-flex">
        <RelButton
          text="Seguir ordenando"
          prependIcon="mdi-chevron-left"
          color="transparent"
          :onclick="goBack"
          light
          flat
        />
      </v-card-title>
      <v-card-text class="card-text-container">
        <div 
          class="confirm-order d-flex flex-column justify-space-between pb-3"
          :class="{ 'passed': orderConfirmed }"
        >
          <div>
            <h2 class="tw-heavy">Mi Orden</h2>
            <h3 class="tc-text-light">{{ restaurantName }}</h3>
            <!-- ORDER LIST -->
            <OrderItemsList />
          </div>
          <!-- ORDER TOTAL -->
          <OrderTotal @confirm-order="orderConfirmed = true" @go-back="goBack"/>
        </div>
        <div 
          class="create-order d-flex flex-column justify-space-between pb-3" 
          :class="{ 'passed': orderConfirmed }"
        >
          <div>
            <h2>Ya casi terminamos</h2>
            <p class="tc-text-light lh-1 w-75">Solo necesitamos los siguientes datos para crear tu orden</p>
            <!-- ORDER FORM -->
            <div class="order-form">
              <v-form ref="orderForm" v-model="valid" lazy-validation class="pt-4">
                <label class="order-form__label" for="inputName" >Pedido a nombre de *</label>
                <v-text-field
                  id="inputName"
                  v-model="client.name"
                  :rules="nameRules"
                  placeholder="Ej. Alfonso Quintero"
                  class="order-form__input mb-2"
                  density="compact"
                  required
                ></v-text-field>

                <v-textarea
                  id="inputNotes"
                  v-model="client.notes"
                  placeholder="Alguna nota sobre el pedido"
                  class="order-form__input mb-4"
                  rows="2"
                  no-resize
                ></v-textarea>

                <v-row v-if="order.typeOrder == 0">
                  <v-col cols="12" class="py-0">
                    <label class="order-form__label" for="inputStreet" >Dirección de envío:</label>
                    <v-text-field
                      id="inputStreet"
                      v-model="client.street"
                      placeholder="Calle *"
                      :rules="requiredRules"
                      class="order-form__input"
                      density="compact"
                      required
                    ></v-text-field>
                  </v-col>

                  <v-col cols="5" class="py-0">
                    <v-text-field
                      id="inputNumber"
                      v-model="client.streetNumber"
                      type="number"
                      @keypress="validateNumber($event)"
                      placeholder="#Exterior"
                      class="order-form__input"
                      density="compact"
                      required
                    ></v-text-field>
                  </v-col>

                  <v-col cols="7" class="py-0">
                    <v-text-field
                      id="inputNeighborhood"
                      v-model="client.neighborhood"
                      placeholder="Colonia *"
                      :rules="requiredRules"
                      class="order-form__input"
                      density="compact"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-switch
                  v-if="order.typeOrder == 0"
                  v-model="rememberAddress"
                  hide-details
                  label="Guardar esta dirección"
                  color="var(--color-rel)"
                ></v-switch>

              </v-form>
            </div>
          </div>

          <div class="d-flex justify-space-between">
            <RelButton text="Regresar" color="background" textColor="#383838" light flat :onclick="() => orderConfirmed = false"/>
            <RelButton text="Enviar pedido" color="secondary-1" textColor="#932EE4" appendIcon="mdi-whatsapp" flat :onclick="validate"/>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script setup>
import OrderItemsList from "@/components/order_view/OrderItemsList.vue";
import OrderTotal from "@/components/order_view/OrderTotal.vue";
import RelButton from "@/components/buttons/RelButton.vue";
import { useMain } from "@/composables/useMain";
import { buildWhatsappMessage } from "@/utils/buildWhatsappMessage";
import { computed, ref, watch, reactive, onBeforeMount } from "vue";
import { TypesOrder } from "@/types/Order.type";

// Data
const { restaurant, order, name, street, streetNumber, neighborhood, savedInfo } = useMain();
const orderConfirmed = ref(false);

// Props
const props = defineProps({
  showOrderProp: {
    required: true,
    default: false
  }
})

// Emits
const emit = defineEmits(["hideOrder"]);

// Computed
const restaurantName = computed(() => restaurant?.value?.name ?? "No restaurant set");
const show = computed({
  get: () => props.showOrderProp,
  set: (value) => emit("hideOrder", value)
})

// Create a watcher that change showOrder when showOrderProp changes


// Methods
function goBack() {
  emit("hideOrder");
  orderConfirmed.value = false;
}

/////////////// FORM VALIDATION ///////////////
const orderForm = ref();

const client = reactive({
  name: "",
  street: "",
  streetNumber: "",
  neighborhood: "",
  notes: "",
});

const valid = ref(false);
const rememberAddress = ref(false);

const nameRules = [
  (v) => !!v || "Se requiere un nombre para el pedido 😬",
  (v) => (v && v.length <= 30) || "No escribas todo tu nombre, no seas wey 😅",
];

const requiredRules = [
  (v) => !!v || "Este campo es requerido 😬",
];

onBeforeMount(() => {
  if (savedInfo) {
    client.name = name.value;
    client.street = street.value;
    client.streetNumber = streetNumber.value;
    client.neighborhood = neighborhood.value;
    rememberAddress.value = true;
  }
});

async function validate() {
  await orderForm.value.validate();
  if (valid.value) {
    saveLocal();
    sendOrder();
  }
}

const saveLocal = () => {
  if (rememberAddress.value) {
    name.value = client.name;
    street.value = client.street;
    streetNumber.value = client.streetNumber;
    neighborhood.value = client.neighborhood;
    savedInfo.value = true;
  } else {
    name.value = "";
    street.value = "";
    streetNumber.value = "";
    neighborhood.value = "";
    savedInfo.value = false;
  } 
};

function sendOrder() {
  if (order.value.total > 0) {
    const message = buildWhatsappMessage({
      phone: restaurant?.value?.phone ?? "",
      menuItems: order.value.menuItemsOrder,
      typeOrder: order.value.typeOrder,
      total: order.value.total,
      client: {
        name: client.name,
        street: client.street,
        streetNumber: client.streetNumber,
        neighborhood: client.neighborhood,
        notes: client.notes,
      }
    });

    window.open(message, "_blank")
  }
}

// Validation for number input
function validateNumber(evt) {
  let theEvent = evt || window.event;

  // Handle paste
  if (theEvent.type === 'paste') {
    key = evt.clipboardData.getData('text/plain');
  } else {
  // Handle key press
    var key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);
  }  
  // regex that accepts only letters and numbers
  var regex = /[0-9]/;
  if( !regex.test(key) ) {
    theEvent.returnValue = false;
    if(theEvent.preventDefault) theEvent.preventDefault();
  }
}

</script>

<style lang="scss" scoped>
.card-text-container {
  position: relative;
  max-width: 100vw;
}
.confirm-order {
  transition: var(--transition-normal);
  max-width: 100vw;
  height: 100%;
  position: absolute;
  padding: 16px 24px 10px;
  opacity: 1;
  top: 0;
  left: 0;
}

.confirm-order.passed {
  left: -100vw;
  opacity: 0;
}

.create-order {
  transition: var(--transition-normal);
  width: 100vw;
  max-width: 100vw;
  height: 100%;
  position: absolute;
  padding: 16px 24px 10px;
  top: 0;
  left: 100vw;
  opacity: 0;
}

.create-order.passed {
  left: 0;
  opacity: 1;
}

.order-form {
  &__label {
    font-size: var(--normal-font-size);
    font-weight: 600;
    color: var(--color-title);
  }

  &__input {
    :deep(.v-field__overlay) {
      border-radius: 0.8rem;
    }

    :deep(.v-field__outline) {
      display: none;
    }
  }
}
</style>
