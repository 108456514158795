<template>
  <v-btn
    :color="`var(--color-${color})`"
    :flat="flat"
    :disabled="disabled"
    :loading="loading"
    :prependIcon="prependIcon"
    :appendIcon="appendIcon"
    @click="onClick"
  >
    {{ text }}
  </v-btn>
</template>

<script setup lang="ts">
import { computed, PropType } from "vue";

type Colors =
  | "pink"
  | "blue"
  | "green"
  | "yellow"
  | "orange"
  | "red"
  | "purple"
  | "white"
  | "black"
  | "transparent"
  | "background"
  | "rel"
  | "high-contrast"
  | "light-contrast"
  | "secondary-1"
  | "secondary-2";

const { color, text, textColor, light, flat, disabled, prependIcon, appendIcon, loading, onClick, } = defineProps({
  color: { type: String as PropType<Colors>, default: "pink" },
  textColor: { type: String, default: "#fff" },
  text: { type: String, default: "Rel Button" },
  light: { type: Boolean, default: false },
  flat: { type: Boolean, default: false },
  disabled: { type: Boolean, default: false },
  prependIcon: { type: String, default: "" },
  appendIcon: { type: String, default: "" },
  loading: { type: Boolean, default: false },
  onClick: { type: Function as PropType<() => void>, default: () => {} },
});

</script>

<style lang="scss" scoped>
.v-btn {
  /* Text style */
  letter-spacing: 0;
  text-transform: none;
  font-weight: var(--font-bold);
  :deep(.v-btn__content) {  
    color: v-bind(textColor);
  }
  :deep(.v-icon) {
    color: v-bind(textColor);
  }
  /* Button style */
  border-radius: 11px;
  box-shadow: var(--bs-normal);
  &:hover,
  &:focus {
    box-shadow: var(--bs-dark);
  }
}

.v-btn--flat {
  box-shadow: none !important;
  &:hover,
  &:focus {
    box-shadow: none !important;
  }
}
</style>
