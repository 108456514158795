<template>
  <div class="menu-item">
    <div class="menu-item__info" @click="openDialog">
      <h3>{{ language == 'es' ? menuItem.name_es : menuItem.name_en }}</h3>
      <p class="tc-text-light lh-1" v-if="menuItem.description_es">{{ language == 'es' ? menuItem.description_es : menuItem.description_en }}</p>
      <p v-if="menuItem.promoPrice">
        <span class="price tc-pink mr-4">$ {{ menuItem.promoPrice }}</span>
        <span class="price tc-checker">$ {{ menuItem.price }}</span>
      </p>
      <span v-else class="price">$ {{ menuItem.price }} MXN</span>
    </div>
    <div v-if="!staticMenu && !restaurant?.closed" class="menu-item__options">
      <span class="tc-title ts-h3 tw-medium">{{ itemQuantity }}</span>
      <RelButtonIcon color="secondary-1" textColor="#932EE4" @click="addProduct"/>
    </div>
  </div>
<!-- 
  <DialogMenuItem :openDialog="dialogDetails" :menuItem="menuItem" @hide-dialog="dialogDetails = false" /> -->
</template>

<script setup lang="ts">
import RelButtonIcon from '@/components/buttons/RelButtonIcon.vue';
import { useMain } from '@/composables/useMain';
import { useMainStore } from '@/stores/mainStore';
import { storeToRefs } from 'pinia';
import { useRoute } from 'vue-router'
import { ref, computed }  from 'vue';
// Types
import { MenuItem } from '@/types/MenuItem.type';

// Props
const { menuItem } = defineProps<{
  menuItem: MenuItem;
}>();

//Use store
const mainStore = useMainStore()
const { language } = storeToRefs(mainStore)
const { restaurant } = storeToRefs(mainStore)

// Data
let dialogDetails = ref(false);
const { addMenuItemToOrder, getItemOrderQuantity } = useMain();
const route = useRoute();
const staticMenu = ref(false);

staticMenu.value = route.query.static == 'true' ? true : false;

// Computed
const itemQuantity = computed(() => {
  return getItemOrderQuantity(menuItem._id);
});

// Methods
function addProduct() {
  addMenuItemToOrder(menuItem);
}

function openDialog() {
  dialogDetails.value = !dialogDetails.value;
}
</script>

<style lang="scss" scoped>
.menu-item {
  width: 100%;
  height: fit-content;
  display: flex;
  gap: 1rem;

  .menu-item__info {
    max-width: 80%;
    .price {
      font-size: 1.1rem;
      font-weight: var(--font-bold);
    }
  }

  .menu-item__options {
    display: flex;
    align-items: center;
    gap: 0.6rem;
    margin-left: auto;
  }
}
</style>